<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import BaseSelect from '@/components/ui/select/BaseSelect.vue';
import GraduatedDefines from '@/defines/graduated';
import LocalPageLoader from '@/components/ui/loaders/components/LocalPageLoader.vue';
import EmptyContent from '@/components/ui/empty/EmptyContent.vue';
import FixPhraseService from '@/services/fixphrase';
import BaseCheckbox from '@/components/ui/checkbox/BaseCheckbox.vue';
import { useInitialGraduated } from '@/composables/useInitialGraduated';

import type { GraduatedFixedPhrase } from '@/types/fixedPhrase';

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['submit', 'close']);

const store = useStore();

const isLoading = ref(false);
const graduatedFixedPhrases = ref<GraduatedFixedPhrase[]>([]);
const selectedGraduatedFixedPhrasesIds = ref<number[]>([]);

const { graduates, selectedGraduateId, initializeGraduatedSelection } =
  useInitialGraduated();

const graduatedYearToLabelName = (year: number) => {
  const res = GraduatedDefines.find(graduated => graduated.year === year);
  if (res !== undefined) return res.name_jp;
  return `${year}年卒`;
};

const fetchGraduatedFixedPhrases = async (graduatedId: number) => {
  isLoading.value = true;
  const res = await FixPhraseService.postFixPhraseList(graduatedId);
  graduatedFixedPhrases.value = res.fixphrase_list;
  isLoading.value = false;
};

const handleChangeGraduate = async (value: number) => {
  selectedGraduateId.value = value;
  await fetchGraduatedFixedPhrases(value);
  resetSelectedFixedPhraseIds();
};

const handleSelectGraduatedFixedPhrase = (checked: boolean, id: number) => {
  if (checked) {
    selectedGraduatedFixedPhrasesIds.value.push(id);
  } else {
    selectedGraduatedFixedPhrasesIds.value =
      selectedGraduatedFixedPhrasesIds.value.filter(fixedPhraseId => {
        return fixedPhraseId !== id;
      });
  }
};

const handleChangeAllSelect = (checked: boolean) => {
  if (checked) {
    selectedGraduatedFixedPhrasesIds.value = graduatedFixedPhrases.value.map(
      fixedPhrase => fixedPhrase.id,
    );
  } else {
    selectedGraduatedFixedPhrasesIds.value = [];
  }
};

const displaySettingContentLabel = (fixedPhrase: GraduatedFixedPhrase) => {
  const content = [];
  if (fixedPhrase.line_content !== '') content.push('LINE');
  if (fixedPhrase.mail_content !== '') content.push('メール');
  return content.join('');
};

const handleDuplicateGraduatedFixedPhrases = async () => {
  const payload = {
    duplicate_from_graduated_id: Number(selectedGraduateId.value),
    duplicate_to_graduated_id: store.getters['graduateds/selectedGraduatedId'],
    fixphrase_ids: selectedGraduatedFixedPhrasesIds.value,
  };
  isLoading.value = true;
  const res = await FixPhraseService.postDuplicateFixPhrase(payload);
  isLoading.value = false;

  store.dispatch('notification/VISIBLE_NOTIFICATION', {
    message: res.message,
    type: res.success,
  });
  if (res.success) {
    emit('submit');
    closeModal();
  }
};
const closeModal = () => {
  emit('close');
  resetSelectedFixedPhraseIds();
};

const resetSelectedFixedPhraseIds = () => {
  selectedGraduatedFixedPhrasesIds.value = [];
};

const init = async () => {
  await initializeGraduatedSelection();
  await fetchGraduatedFixedPhrases(selectedGraduateId.value);
};

watch(
  () => props.isVisible,
  async newValue => {
    if (newValue) {
      await init();
    }
  },
);
onMounted(async () => {
  await init();
});
</script>
<template>
  <modal-window
    :is-visible="isVisible"
    :max-width="'1040px'"
    title="複製するテンプレートの選択"
    @close="closeModal"
  >
    <template #content>
      <div class="modal-contents">
        <div class="modal-contents-select">
          <base-select
            :model-value="selectedGraduateId"
            @change="handleChangeGraduate($event.target.value)"
          >
            <option
              v-for="graduate in graduates"
              :key="graduate.id"
              :value="graduate.id"
            >
              {{ graduatedYearToLabelName(graduate.year) }}
            </option>
          </base-select>
        </div>
        <div class="modal-contents-table">
          <div v-if="isLoading" class="loading-wrapper">
            <local-page-loader />
          </div>
          <template v-else>
            <div v-if="graduatedFixedPhrases.length > 0" class="table-wrapper">
              <table class="table">
                <thead>
                  <th>
                    <base-checkbox
                      :model-value="
                        graduatedFixedPhrases.length ===
                        selectedGraduatedFixedPhrasesIds.length
                      "
                      @change="handleChangeAllSelect($event.target.checked)"
                    />
                  </th>
                  <th><span>テンプレート名</span></th>
                  <th><span>作成者</span></th>
                  <th><span>設定内容</span></th>
                  <th><span>作成日</span></th>
                </thead>
                <tbody>
                  <tr
                    v-for="fixedPhrase in graduatedFixedPhrases"
                    :key="fixedPhrase.id"
                  >
                    <td>
                      <base-checkbox
                        :model-value="
                          selectedGraduatedFixedPhrasesIds.includes(
                            fixedPhrase.id,
                          )
                        "
                        @change="
                          handleSelectGraduatedFixedPhrase(
                            $event.target.checked,
                            fixedPhrase.id,
                          )
                        "
                      />
                    </td>
                    <td>{{ fixedPhrase.name }}</td>
                    <td>
                      {{ fixedPhrase.staff.firstname }}
                      {{ fixedPhrase.staff.lastname }}
                    </td>
                    <td>
                      {{ displaySettingContentLabel(fixedPhrase) }}
                    </td>
                    <td>
                      {{
                        moment(fixedPhrase.created_at).format(
                          'YYYY/MM/DD HH:mm',
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              <empty-content>テンプレートが作成されていません</empty-content>
            </div>
          </template>
        </div>
        <div class="modal-contents-bottom">
          <button-base
            :button-text="'キャンセル'"
            button-type="secondary"
            @click="closeModal"
          />
          <button-base
            :button-text="'複製する'"
            button-type="primary"
            :disabled="selectedGraduatedFixedPhrasesIds.length === 0"
            :is-loading="isLoading"
            @click="handleDuplicateGraduatedFixedPhrases"
          />
        </div>
      </div>
    </template>
  </modal-window>
</template>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@include tableStyle;

.modal-contents {
  position: relative;
  padding: 20px;

  &-bottom {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 20px;

    button {
      height: 52px;
      width: 264px;
    }
  }
}
.loading-wrapper {
  height: 420px;
}
.modal-contents-select {
  margin: 0 auto;
  max-width: 320px;
}
.table-wrapper {
  overflow-y: scroll;
  height: 420px;

  .table {
    &:has(.base-checkbox) {
      th,
      td {
        &:first-of-type {
          min-width: 60px;
          padding-right: 0;
          vertical-align: baseline;
        }
      }
    }
  }
}
.modal-contents-table {
  margin-top: 32px;
}
.modal-contents-bottom {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}
</style>
